<template>
    <v-container class="day-panel">
      <v-row>
          <p class="day-label">{{ today }}</p>
          <p v-bind:class="[(isDayComplete(propDay) ? 'complete' : 'incomplete'), 'day-hour']">{{ getDayHoursAsString(propDay) }}</p>
          <v-btn class="day-delete" outlined fab color="primary" @click="removeDay()">
            <v-icon icon="mdi-delete" color="white"/>
          </v-btn>
      </v-row>
      <v-row>
        <v-col class="min-width">
          <div>Matin</div>
          <Datepicker v-if="preferences.useTimepicker" v-model="propDayBeginHour" timePicker textInput autoApply locale="fr" />
          <input v-if="!preferences.useTimepicker" :value="propDayBeginHourAsString" class="custom-input" @focus="$event.target.select()" @blur="updateDayBeginHour($event.target, 'DAY_BEGIN_HOUR', $event.target.value)"/>
        </v-col>
        <v-col class="min-width">
          <div>Début midi</div>
          <Datepicker v-if="preferences.useTimepicker" v-model="propBreakBeginHour" timePicker textInput locale="fr" />
          <input v-if="!preferences.useTimepicker" :value="propBreakBeginHourAsString" class="custom-input" @focus="$event.target.select()" @blur="updateDayBeginHour($event.target, 'BREAK_BEGIN_HOUR', $event.target.value)"/>
        </v-col>
        <v-col class="min-width">
          <div>Fin midi</div>
          <Datepicker v-if="preferences.useTimepicker" v-model="propBreakEndHour" timePicker textInput locale="fr" />
          <input v-if="!preferences.useTimepicker" :value="propBreakEndHourAsString" class="custom-input" @focus="$event.target.select()" @blur="updateDayBeginHour($event.target, 'BREAK_END_HOUR', $event.target.value)"/>
        </v-col>
        <v-col class="min-width">
          <div>Soir</div>
          <Datepicker v-if="preferences.useTimepicker" v-model="propDayEndHour" timePicker textInput locale="fr" />
          <input v-if="!preferences.useTimepicker" :value="propDayEndHourAsString" class="custom-input" @focus="$event.target.select()" @blur="updateDayBeginHour($event.target, 'DAY_END_HOUR', $event.target.value)"/>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

moment.locale('fr');

export default {
  name: 'CRDayPanel',
  props: {
    day: Object
  },
  data() {
    return {
				today: this.capitalizeFirstLetter(moment(this.day.date).format('dddd DD MMMM')),
    };
  },
  methods: {
    removeDay() {
      if(confirm("Voulez vous vraiment supprimer cette journée ?")) {
        this.$store.dispatch('removeDay', this.day);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    manageTimeAsString(value) {
      return value
    },
    updateDayBeginHour(target, type, value) {
        //Get string separated by :
        let valueToUpdate = '00:00:00';
        let time = this.manageTimeAsString(value).split(':')
        if (time.length > 1) {
          //Get hours and minutes
          let hours = parseInt(time[0])
          let minutes = parseInt(time[1])

          if (!minutes) {
            minutes = 0
          }

          //Set hours and minutes
          valueToUpdate = (hours < 10 ? '0' + hours : hours) + ':' + ((minutes <= 5 ? minutes + '0' : minutes < 10 ? '0' + minutes : minutes)) + ':00';
        }
        else {
          //Get hours
          let hours = parseInt(time[0])
          //Set hours
          valueToUpdate = (hours < 10 ? '0' + hours : hours) + ':00:00';
        }

        //We check if the value is the same as before
        let previousValue = null;
        if (type == 'DAY_BEGIN_HOUR') {
          previousValue = this.day.dayBeginHour == valueToUpdate ? this.day.dayBeginHour : null;
        }
        else if (type == 'BREAK_BEGIN_HOUR') {
          previousValue = this.day.breakBeginHour == valueToUpdate ? this.day.breakBeginHour : null;
        }
        else if (type == 'BREAK_END_HOUR') {
          previousValue = this.day.breakEndHour == valueToUpdate ? this.day.breakEndHour : null;
        }
        else if (type == 'DAY_END_HOUR') {
          previousValue = this.day.dayEndHour == valueToUpdate ? this.day.dayEndHour : null;
        }

        //Update value
        if (previousValue) {
          let value = moment(previousValue, 'HH:mm').toDate()
          target.value = (value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) + ':' + (value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes());
        }
        else {
          this.$store.dispatch('updateDay', { day: this.day, type: type, time: valueToUpdate });
        }
    }
  },
  computed: {
    ...mapState(['preferences']),
    propDay: {
      get () { return this.day },
    },
    propDayBeginHour: {
      get () {
        let time = moment(this.day.dayBeginHour, 'HH:mm').toDate();
        return {
            hours: time.getHours(),
            minutes: time.getMinutes()
        }
      },
      set(value) {
        let timeAsString = (value.hours < 10 ? '0' + value.hours : value.hours) + ':' + (value.minutes < 10 ? '0' + value.minutes : value.minutes) + ':00';
        this.$store.dispatch('updateDay', { day: this.day, type: 'DAY_BEGIN_HOUR', time: timeAsString });
      }
    },
    propDayBeginHourAsString: {
      get () {
        let value = moment(this.day.dayBeginHour, 'HH:mm').toDate()
        return (value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) + ':' + (value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes());
      },
    },
    propBreakBeginHour: {
      get () {
        let time = moment(this.day.breakBeginHour, 'HH:mm').toDate();
        return {
            hours: time.getHours(),
            minutes: time.getMinutes()
        }
      },
      set(value) {
        let timeAsString = value.hours + ':' + value.minutes;
        let time = moment(timeAsString, 'HH:mm').toDate();
        this.$store.dispatch('updateDay', { day: this.day, type: 'BREAK_BEGIN_HOUR', time: time });
      }
    },
    propBreakBeginHourAsString: {
      get () {
        let value = moment(this.day.breakBeginHour, 'HH:mm').toDate();
        return (value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) + ':' + (value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes());
      },
    },
    propBreakEndHour: {
      get () {
        let time = moment(this.day.breakEndHour, 'HH:mm').toDate();
        return {
            hours: time.getHours(),
            minutes: time.getMinutes()
        }
      },
      set(value) {
        let timeAsString = value.hours + ':' + value.minutes;
        let time = moment(timeAsString, 'HH:mm').toDate();
        this.$store.dispatch('updateDay', { day: this.day, type: 'BREAK_END_HOUR', time: time });
      }
    },
    propBreakEndHourAsString: {
      get () {
        let value = moment(this.day.breakEndHour, 'HH:mm').toDate();
        return (value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) + ':' + (value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes());
      },
    },
    propDayEndHour: {
      get () {
        let time = moment(this.day.dayEndHour, 'HH:mm').toDate();
        return {
            hours: time.getHours(),
            minutes: time.getMinutes()
        }
      },
      set(value) {
        let timeAsString = value.hours + ':' + value.minutes;
        let time = moment(timeAsString, 'HH:mm').toDate();
        this.$store.dispatch('updateDay', { day: this.day, type: 'DAY_END_HOUR', time: time });
      }
    },
    propDayEndHourAsString: {
      get () {
        let value = moment(this.day.dayEndHour, 'HH:mm').toDate();
        return (value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) + ':' + (value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes());
      },
    },
    ...mapGetters(['getDayHoursAsString','isDayComplete'])
  }
}
</script>

<style>
  .day-panel {
    padding: 5px;
  }

  .min-width {
    min-width: 200px;
  }

  .day-label {
    font-size: 1.5em;
    color: #1867c0;
    caret-color: #1867c0;
    width: 250px;
  }

  .day-hour {
    width: 100px;
  }

  .day-delete {
  }

  .incomplete {
    font-size: 1.5em;
    color: red;
  }

  .complete {
    font-size: 1.5em;
    color: green;
  }
</style>