<template>
  <div class="home">
    <MainPanel/>
  </div>
</template>

<script>
// @ is an alias to /src
import MainPanel from '@/components/MainPanel.vue'

export default {
  name: 'HomeView',
  components: {
    MainPanel
  }
}
</script>
