<template>
    <v-container class="no-padding">
      <v-row>
        <v-col cols="2" class="padding-2">
          <div class="label">Client</div>
          <vue3-simple-typeahead :id="clientId" placeholder="Clients" class="custom-list" :items="clients" :minInputLength="1" :defaultItem="propAction.client" @selectItem="selectClient"/>
        </v-col>
        <v-col cols="2" class="padding-2">
          <div class="label">Projet</div>
          <vue3-simple-typeahead :id="projectId" placeholder="Projects" class="custom-list" :items="projects" :minInputLength="1" :defaultItem="propAction.project" @selectItem="selectProject"/>
        </v-col>
        <v-col class="padding-2">
          <div class="label">Action</div>
          <input label="Action" v-model="propAction.action" class="custom-input"/>
        </v-col>
        <v-col cols="1" class="padding-2">
          <div class="label">Heures</div>
          <input label="Heures" type="number" v-model="propAction.hourNumber" class="custom-input" @keydown.tab.exact="addAction($event)" step="0.25"/>
        </v-col>
        <v-col cols="auto" class="padding-2">
          <v-btn class="action-delete" outlined fab color="primary" @click="removeAction()">
            <v-icon icon="mdi-delete" color="white"/>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
moment.locale('fr');

export default {
  name: 'CRActionPanel',
  props: {
    day: Object,
    action: Object
  },
  data() {
    return {
				clientId: `client_${(Math.random() * 1000).toFixed()}`,
				projectId: `project_${(Math.random() * 1000).toFixed()}`,
    };
  },
  mounted() {
    const input = document.getElementById(this.clientId);
    input.focus();
  },
  methods: {
    ...mapMutations(['SET_HAS_MODIFICATIONS']),
    addAction(event) {
      event.preventDefault();
      
      let nbHoursLeft = this.getHoursLeftInDay(this.day);
      // We check if the action is the last in day
      if (nbHoursLeft > 0 && this.day.actions.indexOf(this.action) === this.day.actions.length - 1) {
        this.$store.dispatch('addAction', this.day);
      }
    },
    removeAction() {
      this.$store.dispatch('removeAction', { day: this.day, action: this.propAction } );
    },
    selectClient(client) {
      // Check if different
      if (this.propAction.client !== client) {
        this.propAction.client = client;
        this.SET_HAS_MODIFICATIONS(true);
      }
		},
    selectProject(project) {
      //Check if different
      if (this.propAction.project !== project) {
        this.propAction.project = project;
        this.SET_HAS_MODIFICATIONS(true);
      }
		},
  },
  computed: {
    ...mapState(['projects','clients']),
    ...mapGetters(['getHoursLeftInDay']),
    propAction: {
      get () { return this.action },
    },
  }
}
</script>

<style>
  .padding-2 {
    padding: 2px;
  }
  
  .label {
    color: #1867c0;
    caret-color: #1867c0;
    font-size: 14px;
  }

  .action-delete {
    margin-top: 22px;
  }
</style>