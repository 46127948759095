<template>
  <v-container>
    <v-row>
      <v-col cols="auto" class="no-padding">
        <v-btn color="primary" @click="addAction()">+</v-btn> 
      </v-col>
      <v-col class="actions-panel">
        <div v-for="item of day.actions" :key="item">
          <CRActionPanel :day="day" :action="item"></CRActionPanel>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRActionPanel from './CRActionPanel'
import moment from 'moment'
moment.locale('fr');

export default {
  name: 'CRActionsByDayPanel',
  components: { CRActionPanel },
  props: {
    day: Object
  },
  methods: {
    calculateDayHours() {
      const dayBeginHour = this.parseTime(this.day.dayBeginHour);
      const dayEndHour = this.parseTime(this.day.dayEndHour);
      var durationDay = moment.duration(dayEndHour.diff(dayBeginHour));
      var dayMins = durationDay.asMinutes();
      
      const breakBeginHour = this.parseTime(this.day.breakBeginHour);
      const breakEndHour = this.parseTime(this.day.breakEndHour);
      var durationBreak = moment.duration(breakEndHour.diff(breakBeginHour));
      var breakMins = durationBreak.asMinutes();

      var totalMinutes = dayMins - breakMins;
      
      var hours = Math.floor(totalMinutes / 60);          
      var minutes = totalMinutes % 60;
      return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    },
    parseTime(time) {
      return moment(time, 'HH:mm');
    },
    addAction() {
      this.$store.dispatch('addAction', this.day);
    }
  },
  computed: {
  }
}
</script>

<style>
  .actions-panel {
    padding: 0px 15px;
  }

  .txt {
  }
</style>