<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style>
.v-row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-row + .v-row {
  margin-top: 0 !important;
}
</style>
