<template>
  <v-container>

    <div v-if="showDatePicker" class="datepicker">
      <!-- <Datepicker v-model="picked" @update:modelValue="initWeek(picked)" inline autoApply/> -->
      <Datepicker v-model="picked" @update:modelValue="initWeek(picked)" inline autoApply locale="fr" />
    </div>

    <!-- We have to defined the user if it is not already define -->
    <v-container v-if="!selectedUser">
      <div>Veuillez sélectionner un utilisateur</div>
      <select v-model="selected" @change="selectUser(selected)" class="custom-select" style="width:200px;">
        <option v-for="user in users" v-bind:value="user" :key="user">
          {{ user }}
        </option>
      </select>
    </v-container>

    <v-container v-if="selectedUser && !showDatePicker" class="main">
      <div class="main">
        <div class="content">
          <v-row>
            <h1 class="week-title margin-auto">Semaine du <b>{{ weekLabel }}</b></h1>
            <v-btn class="btn-top" outlined fab color="primary" @click="displayDatePicker()">
              <v-icon icon="mdi-calendar" color="white"/>
            </v-btn>
            <v-btn class="btn-top" outlined fab color="primary" @click="$router.push('/preferences')">
              <v-icon icon="mdi-cog" color="white"/>
            </v-btn>
            <v-btn class="btn-top" outlined fab color="primary" @click="$router.push('/whatsnew')">
              <v-icon icon="mdi-playlist-check" color="white"/>
            </v-btn>
          </v-row>

          <div v-if="week">
            <div v-for="item of week.days" :key="item.date">
              <CRDayPanel :day="item"></CRDayPanel>
              <CRActionsByDayPanel :day="item"></CRActionsByDayPanel>
            </div>
          </div>
        </div>

        <v-container class="footer">
          <v-row class="btn-panel">
            <v-btn class="margin-5" @click="generateCR()">Generation du CR</v-btn>
            <v-btn class="margin-5" @click="saveCR()">Sauvegarde du CR</v-btn>
            <label v-if="hasModification" class="message-need-save">Vous avez des modifications non sauvegardées</label>
          </v-row>
        </v-container>
      </div>
    </v-container>

    <v-alert :model-value="showAlertSaveSuccess" type="success" transition="v-slide-y-reverse-transition" class="alert-position">Le CR a été sauvegardé.</v-alert>
    <v-alert :model-value="showAlertSaveError" type="error" transition="v-slide-y-reverse-transition" class="alert-position">Un problème est survenue lors de la sauvegarde du CR.</v-alert>
  </v-container>
</template>

<script>
import { mapState, mapMutations, useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'
import CRDayPanel from './CRDayPanel'
import CRActionsByDayPanel from './CRActionsByDayPanel'

export default {
  name: 'MainPanel',
  components: { CRDayPanel, CRActionsByDayPanel },
  beforeCreate() {
    this.$store.dispatch('getUsers');
    this.$store.dispatch('getProjects');
    this.$store.dispatch('getClients');
    this.$store.dispatch('initWeek', new Date());
  },
  setup() {
    const store = useStore()

    // same as beforeRouteLeave option with no access to `this`
    onBeforeRouteLeave((to, from) => {
      if (store.state.hasModification) {
        const answer = window.confirm(
          'Vous avez des modifications non sauvegardées. Voulez-vous vraiment quitter ?'
        )
        // cancel the navigation and stay on the same page
        if (!answer) return false
      }
    })
  },
  computed:{
    ...mapState(['selectedUser','users', 'week', 'weekLabel', 'showAlertSaveSuccess', 'showAlertSaveError', 'hasModification']),
  },
  data() {
    return {
      picked: new Date(),
      showDatePicker: false,
    }
  },
  methods: {
    ...mapMutations(['SET_SHOW_ALERT_SAVE_SUCCESS', 'SET_SHOW_ALERT_SAVE_ERROR']),
    initWeek(date) {
      this.$store.dispatch('initWeek', date);
      this.showDatePicker = false;
    },
    displayDatePicker() {
      this.showDatePicker = true;
    },
    selectUser(user) {
      this.$store.dispatch('selectUser', { user, date: new Date() });
    },
    generateCR() {
      this.$store.dispatch('generateCR');
    },
    saveCR() {
      this.saveCRWithCheck(false);
    },
    saveCRWithCheck(checkModification) {
      if (!checkModification || this.$store.state.hasModification) {
        this.$store.dispatch('saveCR');
      }
    },
    saveCRWithTimeout() {
      setTimeout(() => this.saveCRWithCheck(true), 10000);
    },
    hideAlert(type) {
      if (type == 'ALERT_SAVE_SUCCESS') {
        setTimeout(() => this.SET_SHOW_ALERT_SAVE_SUCCESS(false), 3000);
      }
      else if (type == 'ALERT_SAVE_ERROR') {
        setTimeout(() => this.SET_SHOW_ALERT_SAVE_ERROR(false), 3000);
      }
    },
  },
  watch: {
    showAlertSaveSuccess() {
      if (this.showAlertSaveSuccess) {
        this.hideAlert('ALERT_SAVE_SUCCESS');
      }
    },
    showAlertSaveError() {
      if (this.showAlertSaveError) {
        this.hideAlert('ALERT_SAVE_ERROR');
      }
    },
    hasModification() {
      if (this.hasModification) {
        this.saveCRWithTimeout();
      }
    }
  },
}
</script>

<style>
  .custom-select {
    border: 1px solid black;
  }

  .content {
    padding-bottom: 75px;
  }

  .footer {
    background-color: white;
    height: 75px;
    position: fixed;
    bottom: 0px;
    border-top: 1px solid lightgray;
  }

  .datepicker {
    margin: 200px auto;
    width: fit-content;
  }

  .week-title {
    color: black;
  }

  .margin-auto {
    margin: auto;
  }

  .btn-panel {
    width: fit-content;
    margin: 15px auto;
  }
  
  .margin-5 {
    margin: 5px;
  }

  .alert-position {
    position: fixed;
    bottom: 80px;
    width: 85%;
  }

  .btn-top {
    margin-left: 10px;
  }

  .custom-input {
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 4px;
    width: 100%;
  }

  .custom-list input {
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 4px;
    width: 100%;
  }

  .message-need-save {
    color: red;
    font-weight: bold;
    margin: 12px;
  }
  
  .no-padding {
    padding: 0px;
  }
</style>
