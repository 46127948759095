import { createStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'
moment.locale('fr');

export default createStore({
  state: {
    users: [],
    selectedUser: localStorage.getItem('selectedUser'),
    preferences: localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : {},
    week: null,
    weekLabel: '',
    hasModification: false,
    projects: [],
    clients: [],
    showAlertSaveSuccess: false,
    showAlertSaveError: false
  },
  getters: {
    getHoursLeftInDay: () => (day) => {
      let hours = 0
      if (day.actions) {
        day.actions.forEach(element => {
          hours += element.hourNumber
        })
      }
      return getDayHours(day) - hours
    },
    getDayHoursAsString: () => (day) => {
      let mins = calculateDayMins(day)
      return convertMinutesToHours(mins)
    },
    isDayComplete: () => (day) => {
      let hours = 0
      if (day.actions) {
        day.actions.forEach(element => {
          hours += element.hourNumber
        })
      }
      return getDayHours(day) - hours == 0
    },
  },
  mutations: {
    SET_WEEK(state, week) {
      state.week = week
    },
    SET_WEEK_LABEL(state, weekLabel) {
      state.weekLabel = weekLabel
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_HAS_MODIFICATIONS(state, hasModification) {
      state.hasModification = hasModification
    },
    SET_SELECTED_USER(state, selectedUser) {
      state.selectedUser = selectedUser
      localStorage.setItem('selectedUser', selectedUser)
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_PREFERENCES(state, preferences) {
      state.preferences = preferences
      localStorage.setItem('selectedPreferences', JSON.stringify(preferences))
    },
    SET_SHOW_ALERT_SAVE_SUCCESS(state, showAlertSaveSuccess) {
      state.showAlertSaveSuccess = showAlertSaveSuccess
    },
    SET_SHOW_ALERT_SAVE_ERROR(state, showAlertSaveError) {
      state.showAlertSaveError = showAlertSaveError
    },
  },
  actions: {
    selectUser({ commit, dispatch }, { user, date }) {
      commit('SET_SELECTED_USER', user)
      dispatch('initWeek', date);
    },
    updateUseTimepicker({ commit }, useTimepicker) {
      this.state.preferences.useTimepicker = useTimepicker
      commit('SET_PREFERENCES', this.state.preferences)
    },
    updateDay({ commit }, params) {
      let day = params.day
      let type = params.type
      let time = params.time

      var indexDay = this.state.week.days.indexOf(params.day)
      day = this.state.week.days[indexDay];

      if (type == 'DAY_BEGIN_HOUR') {
        day.dayBeginHour = time
      }
      else if (type == 'BREAK_BEGIN_HOUR') {
        day.breakBeginHour = time
      }
      else if (type == 'BREAK_END_HOUR') {
        day.breakEndHour = time
      }
      else if (type == 'DAY_END_HOUR') {
        day.dayEndHour = time
      }

      commit('SET_WEEK', this.state.week)
      commit('SET_HAS_MODIFICATIONS', true)
    },
    removeDay({ commit }, day) {
      var index = this.state.week.days.indexOf(day)
      this.state.week.days.splice(index, 1)
      commit('SET_WEEK', this.state.week)
      commit('SET_WEEK_LABEL', getWeekLabel(this.state.week))
      commit('SET_HAS_MODIFICATIONS', true)
    },
    addAction({ commit, getters }, day) {
      day.actions = day.actions || []
      day.actions.push({
        client: '',
        project: '',
        techno: '',
        action: '',
        hourNumber: getters.getHoursLeftInDay(day),
        mantis: '',
        commentary: ''
      })
      commit('SET_WEEK', this.state.week)
      commit('SET_HAS_MODIFICATIONS', true)
    },
    removeAction({ commit }, { day, action } ) {
      var indexDay = this.state.week.days.indexOf(day)
      var indexAction = this.state.week.days[indexDay].actions.indexOf(action)
      this.state.week.days[indexDay].actions.splice(indexAction, 1)
      commit('SET_WEEK', this.state.week)
      commit('SET_HAS_MODIFICATIONS', true)
    },




    getUsers({ commit }) {
      axios.get(process.env.VUE_APP_ROOT_API + '/users').then(response => {
        commit('SET_USERS', response.data)
      })
    },
    getProjects({ commit }) {
      axios.get(process.env.VUE_APP_ROOT_API + '/projects').then(response => {
        commit('SET_PROJECTS', response.data)
      })
    },
    getClients({ commit }) {
      axios.get(process.env.VUE_APP_ROOT_API + '/clients').then(response => {
        commit('SET_CLIENTS', response.data)
      })
    },
    initWeek({ commit }, date) {
      if (this.state.selectedUser) {
        let paramUser = 'user=' + this.state.selectedUser;
        let paramDate = '';
        if (date) {
          paramDate = '&date=' + moment(date).format('L');
        }
        axios.get(process.env.VUE_APP_ROOT_API + '/init-week?' + paramUser + paramDate).then(response => {
          commit('SET_WEEK', response.data)
          commit('SET_WEEK_LABEL', getWeekLabel(response.data))
        })
      }
    },
    generateCR() {
      axios({
          url: process.env.VUE_APP_ROOT_API + '/generate',
          method: 'POST',
          responseType: 'blob',
          data: this.state.week
      }).then((response) => {
        let filename = this.state.week.weekName + "_" + this.state.selectedUser + ".xlsx";
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    },
    saveCR({ commit }) {
      axios({
          url: process.env.VUE_APP_ROOT_API + '/save',
          method: 'POST',
          responseType: 'blob',
          data: this.state.week
      }).then(() => {
        commit('SET_HAS_MODIFICATIONS', false)
        commit('SET_SHOW_ALERT_SAVE_SUCCESS', true)
      })
      .catch(error => {
        error => this.status = error.response.data.status;
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        commit('SET_SHOW_ALERT_SAVE_ERROR', true)
      });
    },
  },
  modules: {
  }
})

function getWeekLabel(week) {
  return capitalizeFirstLetter(moment(week.days[0].date).format('dddd DD MMMM'));
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function parseTime(time) {
  return moment(time, 'HH:mm');
}

function calculateDayMins(day) {
  const dayBeginHour = parseTime(day.dayBeginHour);
  const dayEndHour = parseTime(day.dayEndHour);
  var durationDay = moment.duration(dayEndHour.diff(dayBeginHour));
  var dayMins = durationDay.asMinutes();
  
  const breakBeginHour = parseTime(day.breakBeginHour);
  const breakEndHour = parseTime(day.breakEndHour);
  var durationBreak = moment.duration(breakEndHour.diff(breakBeginHour));
  var breakMins = durationBreak.asMinutes();

  return dayMins - breakMins;
}

function convertMinutesToHours(minutes) {
  var hours = Math.floor(minutes / 60);          
  minutes = minutes % 60;
  return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
}

function getDayHours(day) {
  let mins = calculateDayMins(day)
  return mins / 60;
}
